import React, { useState } from 'react';

import { useRouter } from 'next/router';

import MenuDropdown from '@app/components/common/MenuDropdown/MenuDropdown';
import { Check } from '@app/components/icons/check';
import Netherland from '@app/components/icons/flags/netherland';
import USA from '@app/components/icons/flags/usa';
import { useBreakpoint } from '@app/lib/hooks/use-breakpoint';
import { MenuItem } from '@mui/material';
import cn from 'classnames';

export default function LocaleDropdownUi() {
    const router = useRouter();
    const breakpoints = useBreakpoint();
    const { pathname, asPath, query } = router;
    const [locale, setLocale] = useState(router.locale ?? 'EN');
    const dropdownOptions = [
        {
            label: 'en',
            value: 'ENGLISH',
            icon: USA
        },
        {
            label: 'nl',
            value: 'NEDERLANDS',
            icon: Netherland
        }
    ];

    const isLargeScreen = ['xs', '2xs', 'sm', 'md', 'lg', 'xl', '2xl', '3xl'].indexOf(breakpoints) === -1;

    const handleLocale = (label: string) => {
        router.push({ pathname, query }, asPath, { locale: label.toLowerCase() });
        localStorage.setItem('language', label);
        setLocale(label);
    };
    return (
        <div className="flex items-center rounded-3xl border-[1px] border-solid !border-[#DDE1EA]">
            <MenuDropdown
                PaperProps={{
                    elevation: 0,
                    sx: {
                        width: 200,
                        overflow: 'hidden',
                        borderRadius: 2,
                        filter: 'drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.15))',
                        mt: 0.5,
                        padding: 0
                    }
                }}
                id="language-menu"
                menuTitle={''}
                menuContent={dropdownOptions.map((dd) => {
                    if (dd.label == locale.toLowerCase()) {
                        return (
                            <p className={'rounded-2xl bg-[#090F1D] px-2 py-[3px] text-xs text-white'} key={dd.label}>
                                {dd.label.toUpperCase()}
                            </p>
                        );
                    }
                })}
                className="!rounded-full"
            >
                {dropdownOptions.map((dd: any) => (
                    <MenuItem data-testid={dd.label} onClick={() => handleLocale(dd.label)} className="justify-between py-4 hover:bg-black-200" key={dd.value}>
                        <div className={cn('body3 flex items-center  gap-3  ', locale === dd.label && '!text-brand-600 ')}>
                            {React.createElement(dd.icon, { className: 'h-5 w-6' })} {dd?.value}
                        </div>
                        {locale === dd.label && <Check className="h-5 w-5" color="#0C50B4" />}
                    </MenuItem>
                ))}
            </MenuDropdown>
        </div>
    );
}
